@import '../../global/styles/variables';

.first-suggestion-modal{
	position: fixed;
  top: 0px;
	left: 0px;
  z-index: 1000;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .first-suggestion-modal--background{
  	position: absolute;
  	top: 0px;
  	left: 0px;
  	height: 100vh;
  	width: 100%;
    background: $neutral-color-low-pure;
    opacity: 0.32;
  }

  .first-suggestion-modal--card{
  	z-index: 10;
  	position: relative;
  	background: $neutral-color-high-pure;
  	border-radius: $border-radius-lg;
  	padding: $spacing-xs;
  	width: fit-content;

    h1{
      text-align: center;
    }

    iframe{
      border-radius: $border-radius-lg;
    }

    .first-suggestion-modal--actions{
      display: flex;      
      align-items: center;
      justify-content: space-between;
    }
  }
}