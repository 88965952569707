////
//// FONTS
////

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body{
  font-family: 'Poppins', sans-serif;
}

$font-family-highlight: 'Poppins', sans-serif;
$font-family-base: 'Poppins', sans-serif;

////
//// FONT SIZE
////

$font-size-xxxs: 12px;
$font-size-xxs: 14px;
$font-size-xs: 16px;
$font-size-sm: 20px;
$font-size-md: 24px;
$font-size-lg: 32px;
$font-size-xl: 40px;
$font-size-xxl: 48px;
$font-size-xxxl: 64px;
$font-size-display: 80px;
$font-size-giant: 96px;

////
//// LINE HEIGHT
////

$line-height-default: 1;
$line-height-xs: 1.15;
$line-height-sm: 1.20;
$line-height-md: 1.33;
$line-height-lg: 1.50;
$line-height-xl: 1.70;
$line-height-xxl: 2;

////
//// BORDER RADIUS
////

$border-radius-none: 0px;
$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 16px;
$border-radius-lg: 24px;
$border-radius-pill: 500px;
$border-radius-circular: 50%;

////
//// BORDER WIDTH
////

$border-width-none: 0px;
$border-width-hairline: 1px;
$border-width-thin: 2px;
$border-width-thick: 4px;
$border-width-heavy: 8px;

////
//// OPACITY
////

$opacity-level-semiopaque: 0.8;
$opacity-level-intense: 0.64;
$opacity-level-medium: 0.32;
$opacity-level-light: 0.16;
$opacity-level-semitransparent: 0.08;

////
//// SHADOWS
////

$shadow-level-1: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
$shadow-level-2: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
$shadow-level-3: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
$shadow-level-4: 0px 16px 48px 0px rgba(0, 0, 0, 0.24);

////
//// SPACING
////

$spacing-quarck: 4px;
$spacing-nano: 8px;
$spacing-xxxs: 16px;
$spacing-xxs: 24px;
$spacing-xs: 32px;
$spacing-sm: 40px;
$spacing-md: 48px;
$spacing-lg: 56px;
$spacing-xl: 64px;
$spacing-xxl: 80px;
$spacing-xxxl: 120px;
$spacing-huge: 160px;
$spacing-giant: 200px;

////
//// COLORS
////

$brand-color-primary-pure: #FFC529;
$brand-color-primary-light: #FFEEA9;
$brand-color-primary-medium: #B78214;
$brand-color-primary-dark: #724907;

$brand-color-secondary-pure: #673EDC;
$brand-color-secondary-light: #E6D8FD;
$brand-color-secondary-medium: #391F9E;
$brand-color-secondary-dark: #190B67;

$neutral-color-low-pure: #14142B;
$neutral-color-low-light: #A0A3BD;
$neutral-color-low-medium: #6E7191;
$neutral-color-low-dark: #4E4B66;

$neutral-color-high-pure: #FCFCFC;
$neutral-color-high-light: #F7F7FC;
$neutral-color-high-medium: #EFF0F6;
$neutral-color-high-dark: #D9DBE9;

$feedback-color-warning-pure: #ED2E7E;
$feedback-color-warning-light: #FED5D5;
$feedback-color-warning-medium: #AA1772;
$feedback-color-warning-dark: #71085E;

$feedback-color-success-pure: #00BA88;
$feedback-color-success-light: #C9FBDA;
$feedback-color-success-medium: #00857B;
$feedback-color-success-dark: #004E59;

////
//// FIXED HEIGHTS
////

$fixed-height-normal: 46px;
$fixed-height-small: 30px;

.show{
	display: block;
}

.hide{
	display: none !important;
}

////
//// MIXINS
////

$break-small-handheld: 360px;
$break-small: 720px;
$break-large: 1024px;
$break-ultra-large: 1800px;
$break-4k: 2300px;

@mixin respond-to($media) {
  @if $media == small-handhelds {
    @media only screen and (max-width: $break-small-handheld) { @content; }
  }
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large + 1) and (max-width: $break-ultra-large - 1) { @content; }
  }
  @else if $media == ultra-wide-screens {
    @media only screen and (min-width: $break-ultra-large + 1) and (max-width: $break-4k - 1) { @content; }
  }
  @else if $media == 4k-screens {
    @media only screen and (min-width: $break-4k) { @content; }
  }
}