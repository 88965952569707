@import '../../global/styles/variables';

.common-input{
	position: relative;
	padding: 0;
	width: 100%;
	min-height: $fixed-height-normal;
	height: fit-content;
	background-color: transparent;
	border: 2px solid $neutral-color-low-pure;
	border-radius: $border-radius-md;

	&.selected{		
		border: 2px solid $neutral-color-low-pure;

		.send-action{
			display: block;
		}

		.input{
			padding-right: 60px;
		}

		label{
			transform: translate(-22px, -22px);
			font-size: $font-size-xxs;
		}
	}

	&.invisible{
		border: 2px solid transparent;
		transition: all .2s ease;

		&.selected, &:hover{
			border: 2px solid $neutral-color-low-pure;
		}
	}

	&.disabled{		
		color: $neutral-color-low-light;
		background: $neutral-color-high-medium;
		border: 2px solid $neutral-color-high-dark;
	}

	&.with-content{
		label{
			transform: translate(-22px, -22px);
			font-size: $font-size-xxs;
		}
	}

	&.white-background{
		background: $neutral-color-high-pure;
	}

	&.half-input{
		width: calc(50% - 12px);
	}

	&.with-shadow{
		box-shadow: $shadow-level-1;
	}

	&.no-border{
		border: none;
	}

	input.input-field{
		font-family: $font-family-base;
		background: transparent;
		width: calc(100% - 32px);
		height: 46px;
		color: $neutral-color-low-medium;
		border: none;
		padding: 0px $spacing-xxxs;
		outline: none;
		box-shadow: none;
	}

	label{
		font-weight: 600;
		color: $neutral-color-low-pure;
		position: absolute;
		top: 0%;
		left: $spacing-xxxs;
		font-size: $font-size-xs;
		line-height: $font-size-xs;
		cursor: text;
		transform: translateY(15px);
		transition: all .2s ease-out;
		text-align: initial;
		padding: 0 $spacing-quarck;

		@include respond-to(handhelds) {
			font-size: $font-size-xxs;
			line-height: $font-size-xxs;
			transform: translateY(10px);		  
		}
	}

	.icon{
		position: absolute;
		right: $spacing-xxxs;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
	}
}