@import 'global/styles/variables';

body{
	background-color: $neutral-color-high-light;
}

.skeleton-card{
	max-width: 720px;
	margin: 32px auto;		
	box-shadow: $shadow-level-1;
	border-radius: 24px;
	overflow: hidden;
	
	.skeleton-background{
		background-color: #fcfcfc;
		background-image: linear-gradient( 
		90deg
		,#fcfcfc,#f5f5f5,#fcfcfc
		);
	}
}

.loading--page{
	position: relative;
	min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
