@import '../../global/styles/variables';

.button-component{
  font-family: $font-family-base;
	font-weight: 600;
	font-size: $font-size-xs;
	border-radius: $border-radius-sm; 
	background-color: $neutral-color-high-dark;
	color: $neutral-color-low-pure;
	box-shadow: none;
  border: none;
  padding: $spacing-nano $spacing-xxxs;
  margin: $spacing-nano 0;
  height: 40px;
  outline: 0;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
  
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
		color: $neutral-color-low-pure;
  }

  &.only-icon{
  	padding: $spacing-nano;
  }

  &.small{
  	height: $fixed-height-small;
  	font-size: $font-size-xxs;
  }

  &.stretched{
  	padding: $spacing-quarck $spacing-xxs;
  }

	&.full-width{
		width: 100%;
	}

	&.center-text{
		text-align: center;
	}

	&.left-text{
		text-align: left;
	}

	&.right-text{
		text-align: right;
	}

	&.high-button{
		height: 56px;
		border-radius: $border-radius-md;
	}

	&.link-component.high-button{
		height: 24px;
	}

	&.round-border{
		border-radius: $border-radius-md;		
	}

	// Colors
	&.primary-color{
		background-color: $brand-color-primary-pure;
		color: $neutral-color-low-pure;
	}

	&.secondary-color{
		background-color: $neutral-color-low-pure;
		color: white;
	}

	&.black-and-white{
		background-color: $neutral-color-high-pure;
		color: $neutral-color-low-pure;		
	}

	&.clear-background{
		background-color: transparent;
		font-weight: 400;
	}

	&.outlined{ 
		background-color: transparent;
		border: 1px solid $neutral-color-high-dark;
		color: $neutral-color-high-dark;

		&.primary-color{
			border-color: $brand-color-primary-pure;
			color: $brand-color-primary-pure;
		}

		&.secondary-color{
			border-color: $neutral-color-low-pure;
			color: $neutral-color-low-pure;
		}
	}

	// Image

	&.with-image, &.with-icon{		
    display: flex;
    justify-content: center;
    align-items: center;

    &.left-side-icon img, &.left-side-icon svg{
			margin-right: 0;
			margin-left: $spacing-nano;
		}

		img{
			max-width: 24px;
			margin-right: $spacing-nano;
		}

		svg{
			max-width: 24px;
			margin-right: $spacing-nano;
		}
	}

	// Shadow
	&.with-shadow{
		box-shadow: $shadow-level-1;
	}
}