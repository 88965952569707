@import '../../global/styles/variables';

#user-login-modal{
	position: fixed;
  top: 0px;
	left: 0px;
  z-index: 1000;
  min-height: 100vh;
  width: 100%;

  .user-login-modal--background{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background: $neutral-color-low-pure;
    opacity: 0.32;
  }
}