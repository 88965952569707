@import '../../global/styles/variables';

.textarea-input{
	position: relative;
	padding: 0;
	width: calc(100% - 32px);
	height: fit-content;
	background-color: transparent;
	border: 2px solid $neutral-color-low-pure;
	border-radius: $border-radius-lg;
	padding: $spacing-xxxs $spacing-xxxs 0;

	&.selected{		
		border: 2px solid $neutral-color-low-pure;

		.send-action{
			display: block;
		}

		.input{
    	padding-right: 60px;
		}

		label{    	
			transform: translate(-22px, -22px);
			font-size: $font-size-xxs;
		}
	}

	&.invisible{
		border: 2px solid transparent;
		transition: all .2s ease;

		&.selected, &:hover{
			border: 2px solid $neutral-color-low-pure;
		}
	}

	textarea{
		font-family: $font-family-base;
		background: transparent;
	  width: 100%;
	  color: $neutral-color-low-medium;
	  border: none;
	  outline: none;
    resize: none;
	}

	textarea:valid + label{
		transform: translate(-22px, -22px);
		font-size: $font-size-xxs;
	}

	label{
    font-weight: 600;
		color: $neutral-color-low-pure;
    position: absolute;
    top: 0%;
    left: $spacing-xxxs;
    font-size: $font-size-xs;
    line-height: $font-size-xs;
    cursor: text;
    background-color: transparent;
    transform: translateY(15px);
    transition: all .2s ease-out;
    text-align: initial;
    padding: 0 $spacing-quarck;

    @include respond-to(handhelds) {
    	font-size: $font-size-xxs;
    	line-height: $font-size-xxs;
    	transform: translateY(10px);		  
    }
	}
}