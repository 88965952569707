@import '../../global/styles/variables';

#user-login--form{
	width: 540px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@include respond-to(handhelds) {
		width: calc(100% - 16px);
  }
}

.user-login--form-forgot-password{
	margin: -24px 0px 28px;
	text-align: right;
	color: $neutral-color-low-light;
	text-decoration: none;
	font-size: $font-size-xxxs;
	font-weight: 500;
	cursor: pointer;
}

.user-login--form-actions{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-login--form--container{
	margin: 0 auto;
	padding: $spacing-xs $spacing-xxl;
	background-color: $neutral-color-high-pure;
	border-radius: $border-radius-lg;
	box-shadow: $shadow-level-1;


	@include respond-to(handhelds) {
		padding: $spacing-xs;
  }

	h1{
		text-align: center;
		margin: 0 0 $spacing-xxs;
		color: $neutral-color-low-pure;
		font-weight: 500;
		font-size: $font-size-md;
		line-height: $line-height-md;
	}

	p{
		margin: $spacing-xs 0;
		text-align: center;
		color: $neutral-color-low-medium;
		font-size: $font-size-xxs;
		line-height: $line-height-lg;
	}

	.user-login--form-signup{
		margin: $spacing-xxxs 0 0;
		text-align: center;
		color: $neutral-color-low-medium;
		font-size: $font-size-xxs;
		line-height: $line-height-lg;
		cursor: pointer;
	}

	.textarea-input label, .common-input label{
		background-color: $neutral-color-high-light;
	}

	.external-label{
		margin-bottom: $spacing-xs;
		font-weight: 600;
		font-size: $font-size-sm;
		line-height: $line-height-md;
		color: $neutral-color-low-pure;
	}

	.user-login--form--input-line{		
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-xs;
	}

	.user-login--form--option-information{
		font-size: $font-size-xxs;
		font-weight: 400;
		color: $neutral-color-low-medium;
	}

	.google-button{
		color: $neutral-color-high-pure;
		background: #4285F4;
		margin: 0px 0px $spacing-xs;

		&:hover{
			color: $neutral-color-high-pure;
			background: #4285F4;			
		}

		div{
			display: flex;
		}

		svg{
			margin-right: $spacing-nano;
		}
	}
}

.submit--login{
	display: flex;
	justify-content: flex-end;
	padding: $spacing-xxxs 0px 0px;
}